import React from 'react';

import Seo from '../components/Seo';
import Layout from '../components/layout/Layout';

export default () => {
  const title = 'Contact Form';
  const description =
    'Build and manage your modern apps deployments without starting from scratch everytime. Get containers, infrastructure, networking, monitoring, ... and much more configured easily, without any manual script.';
  const url = 'https://strio.app/contact';

  return (
    <Layout>
      <Seo title={title} description={description} url={url} />

      <section id="hero" className="relative pa-0">
        <div className="flex items-center min-h-screen relative">
          <div className="container pt-24">
            <div className="text-center text-white lg:px-12 px-8">
              <h1 className="text-4xl lg:text-5xl font-bold leading-none font-display tracking-tight">
                Contact Us
              </h1>
              <p className="text-base md:text-xl text-gray-400 mt-4">
                We're here for you! Let us know how we can help.
              </p>
            </div>
            <div className="flex justify-center px-8 lg:px-24 pb-20 shadow-inner">
              <form
                className="mt-2 md:w-2/3"
                name="Demo Form"
                data-netlify="true"
                method="POST"
                action="/thanks"
              >
                <input type="hidden" name="form-name" value="Demo Form" />
                <div className="mt-4">
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label
                        className="block uppercase tracking-wide text-gray-200 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email*
                      </label>
                      <input
                        className="appearance-none block w-full bg-gray-200 text-lownight border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="email"
                        name="email"
                        type="email"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                      <label
                        className="block uppercase tracking-wide text-gray-200 text-xs font-bold mb-2"
                        htmlFor="first-name"
                      >
                        First Name
                      </label>
                      <input
                        className="appearance-none block w-full bg-gray-200 text-lownight border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="first-name"
                        name="first-name"
                        type="text"
                      />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label
                        className="block uppercase tracking-wide text-gray-200 text-xs font-bold mb-2"
                        htmlFor="last-name"
                      >
                        Last Name
                      </label>
                      <input
                        className="appearance-none block w-full bg-gray-200 text-lownight border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="last-name"
                        name="last-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label
                        className="block uppercase tracking-wide text-gray-200 text-xs font-bold mb-2"
                        htmlFor="company"
                      >
                        Company
                      </label>
                      <input
                        className="appearance-none block w-full bg-gray-200 text-lownight border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="company"
                        name="company"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label
                        className="block uppercase tracking-wide text-gray-200 text-xs font-bold mb-2"
                        htmlFor="message"
                      >
                        Message
                      </label>
                      <textarea
                        className="appearance-none block w-full bg-gray-200 text-lownight border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="message"
                        name="message"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-5">
                      <label className="block text-gray-500 font-bold">
                        <input className="mr-2" type="checkbox" id="marketing" name="marketing" />
                        <span className="text-sm">
                          Yes, I'd like to receive more information on Strio products, events and
                          promotions via email.
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-2 justify-end">
                    <div className="w-full md:w-auto px-3 mb-6 md:mb-0">
                      <button
                        className="w-full inline-flex items-center justify-center px-6 py-2 border border-transparent text-base leading-6 font-medium tracking-wide rounded-md text-white bg-codestream hover:bg-cloud focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
                        type="submit"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
